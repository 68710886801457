import React, { useEffect, useState } from 'react';
import Breadcrumbs from '../Breadcrumbs/Breadcrumbs';
import HeaderFreeMyopiaControl2Lenses from './HeaderFreeMyopiaControl2Lenses.jsx';
import BodyFreeMyopiaControl2Lenses from './BodyFreeMyopiaControl2Lenses.jsx';
import FooterFreeMyopiaControl2Lenses from './FooterFreeMyopiaControl2Lenses.jsx';
import ModalFreeMyopiaControl2Lenses from './ModalFreeMyopiaControl2Lenses.jsx';
import YouTubeModal from '../YouTubeModal/YouTubeModal';
import './FreeMyopiaControl2Lenses.scss';
import { odPushClevertapEvent } from '../../../utils/clevertap.js';

const FreeMyopiaControl2Lenses = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalTermsOpen, setIsModalTermsOpen] = useState(false);

  useEffect(() => {
    odPushClevertapEvent('Page Viewed', {
      pageName: 'Free Myopia Control Lenses',
      pageUrl: window.location.href,
    });
  }, []);
  const videoId = '7lue2Nyzofw';
  const crumbs = [
    { path: '/sg/en', label: 'Homepage' },
    { path: '/sg/en/news', label: 'News' },
    { path: '/sg/en/news/free-myopia-control-2-lenses', label: 'Free Myopia Control 2 Lenses' },
  ];
  const baseImgUrl =
    'https://static.lenskart.com/media/owndays/img/news/free-myopia-control-2-lenses';
  const baseImgUrl2 = 'https://static1.lenskart.com/owndays/img/news/free-myopia-control-2-lenses';

  return (
    <>
      <Breadcrumbs crumbs={crumbs} />
      <main className="fmc2l">
        <HeaderFreeMyopiaControl2Lenses baseImgUrl={baseImgUrl} baseImgUrl2={baseImgUrl2} />
        <BodyFreeMyopiaControl2Lenses
          baseImgUrl={baseImgUrl}
          baseImgUrl2={baseImgUrl2}
          setIsModalOpen={setIsModalOpen}
          setIsModalTermsOpen={setIsModalTermsOpen}
        />
        <FooterFreeMyopiaControl2Lenses />
      </main>
      <YouTubeModal isOpen={isModalOpen} videoId={videoId} onClose={() => setIsModalOpen(false)} />
      <ModalFreeMyopiaControl2Lenses
        isOpen={isModalTermsOpen}
        onClose={() => setIsModalTermsOpen(false)}
      />
    </>
  );
};

export default FreeMyopiaControl2Lenses;
