import React from 'react';
import { ARTICLES } from '../Articles/Articles';
import ArticleDetailsBreadcrumb from './ArticleDetailsBreadcrumb';
import ArticleDetailsHeader from './ArticleDetailsHeader';
import ArticleDetailsFooter from './ArticleDetailsFooter';
import './ArticleDetails.scss';

const symptomsData = [
  'Blurry vision at normal reading distances',
  'Eyestrain or headaches after doing close-up work or reading',
  'You need to hold the reading material farther away to decipher the text',
  'You notice the symptoms worsening when you are tired or in an area with dim lighting',
];

const eyeStepsData = [
  'Your cornea bends light as it enters the eye.',
  'The tiny, circular muscle surrounding the lens contracts or relaxes, changing its shape to bring objects into focus.',
  'When light enters the retina, a layer of tissue at the back of your eye translates light into electrical signals.',
  'Subsequently, your optic nerve transmits these signals to the brain, enabling you to see the image.',
];

const ButtonLink = ({ className, href, children }) => (
  <a className={className} href={href}>
    {children}
  </a>
);

const SG004 = () => {
  const permalink = 'what-you-need-to-know-about-presbyopia';
  const article = ARTICLES?.find(article => article?.permalink === permalink);
  const baseImgUrl = 'https://static.lenskart.com/owndays/img/articles';

  return (
    <>
      <ArticleDetailsBreadcrumb article={article} />
      <div className="article-details">
        <ArticleDetailsHeader article={article} />
        <div className="article-details__content">
          <div className="article-details__container">
            <p
              dangerouslySetInnerHTML={{ __html: article.description }}
              className="article-details__content-004-005__text-description"
            />
            <div className="article-details__button-group-2">
              <ButtonLink
                className="article-details__button-group-2-warp"
                href="/sg/en/eyewear/eyeglasses.html"
              >
                See All Eyeglasses
              </ButtonLink>
              <ButtonLink
                className="article-details__button-group-2-warp"
                href="/sg/en/news/bundle-sale"
              >
                See OWNDAYS Contact Lenses
              </ButtonLink>
            </div>
            <div>
              <h2>What Are The Symptoms Of Presbyopia</h2>
              <p className="article-details__content-004-005__text-description">
                As we have shared, presbyopia develops gradually. You may first notice the following
                symptoms around age 40:
              </p>
            </div>
          </div>
          <div className="article-details__content-004-005__container-list">
            <div className="article-details__content-004-005__symptoms">
              {symptomsData.map((text, index) => (
                <div className="symptom-item" key={index}>
                  <span className="symptom-item__icon" role="img" aria-label="pin">
                    📌
                  </span>
                  <p className="symptom-item__text">{text}</p>
                </div>
              ))}
            </div>
          </div>
          <div className="article-details__container">
            <div>
              <p className="article-details__content-004-005__text-description">
                If you experience the following signs, consider consulting an eye care practitioner
                immediately. Blurry close-up vision can significantly impact your quality of life,
                preventing you from reading, doing close-up work, or enjoying other regular
                activities. An eye care practitioner can assess your condition and properly advise
                you on possible solutions.
              </p>
            </div>
            <div>
              <h2>What Causes Presbyopia</h2>
              <div className="article-details__content-004-005__flex-content">
                <p className="article-details__content-004-005__flex-content-1 article-details__content-004-005__text-description">
                  To better understand presbyopia and what causes it, you first need to know how
                  your eyes’ lenses enable you to see objects clearly. Think of your eye as a camera
                  and your lens as a camera lens. The latter works with your cornea – the eye’s
                  clear, outer ‘window’ – to focus on objects near or far, providing you with clear
                  vision.
                </p>
                <img
                  alt="What Causes Presbyopia"
                  className="article-details__content-004-005__flex-content-2"
                  src={`${baseImgUrl}/sg-004/illust-1.svg`}
                />
              </div>
              <p className="article-details__content-004-005__text-p article-details__content-004-005__pt-25">
                Here is a breakdown of the process:
              </p>
              {eyeStepsData.map((text, index) => (
                <p
                  key={index}
                  className={`article-details__content-004-005__list${
                    index === eyeStepsData.length - 1
                      ? ' article-details__content-004-005__pb-25'
                      : ''
                  }`}
                >
                  <span>{index + 1}</span>
                  {text}
                </p>
              ))}
              <p className="article-details__content-004-005__text-p">
                During childhood through to early adulthood, your eyes’ lenses are soft and
                flexible, allowing them to easily change shape to enable light to land on your
                retina so that you can see objects clearly, whether they are near or far.
              </p>
              <p className="article-details__content-004-005__text-p">
                However, as your lenses continue to grow and age, new layers of cells form,
                thickening your lenses and making them less flexible. As a result, your eyes start
                having trouble focusing light as they should, causing your close-up vision to become
                blurry.
              </p>
            </div>
            <div className="article-details__content-004-005__pb-25">
              <h2>What Are The Treatment Options For Presbyopia?</h2>
              <p className="article-details__content-004-005__text-description">
                Depending on your preferences, lifestyle, and health, an eye care practitioner may
                recommend the following treatment methods to correct presbyopia:
              </p>
              <ol>
                <li className="article-details__content-004-005__pb-25-sp">
                  <h3>
                    <span>Option #1</span>Eyeglasses
                  </h3>
                  <p className="p-img-left p-img-left-004 article-details__content-004-005__text-description">
                    <img
                      alt="Eyeglasses"
                      className="article-details__img-content"
                      loading="lazy"
                      src={`${baseImgUrl}/sg-004/main-2.webp`}
                    />
                    Those struggling with contact lenses can consider a comfortable pair of
                    eyeglasses instead. However, it is a good idea to consult an eye specialist
                    about the magnification power you require to ensure optimal vision clarity and
                    comfort, making reading easier and preventing unnecessary stress and strain on
                    your eyes.
                  </p>
                  <p className="article-details__content-004-005__text-description">
                    Alternatively, consider getting a pair of spectacles with progressive lenses.
                    They are basically multifocal lenses but with a more gradual shift between
                    prescriptions. In fact, they are ideal for those who don’t want a visible line
                    on their glasses. The best thing about progressives lenses is that you only
                    require one pair of glasses whether you need to see near or far. Progressive
                    lenses are usually recommended for individuals 40 years of age or older who have
                    difficulty seeing both near and far or those experiencing discomfort due to
                    blurry vision when looking afar with their reading glasses.
                    <br />
                    At OWNDAYS, we have various types of progressives lenses - Enhanced, Prestige,
                    Prestige Gold, and Prestige Platinum - suited for different reading needs. The
                    price of our progressive glasses (frame and lens) starts from S$198. Head down
                    to our stores today and speak with our eye care practitioners to learn which is
                    suitable for you.
                  </p>
                </li>
                <div className="article-details__intro article-details__intro-2">
                  <h4>Introduction</h4>
                  <div className="article-details__intro-grid article-details__intro-grid-2">
                    <img
                      alt="progressive-cp"
                      loading="lazy"
                      src={`${baseImgUrl}/sg-004/progressive-cp.webp`}
                    />
                    <div>
                      <p className="article-details__content-004-005__text-description">
                        OWNDAYS PROGRESSIVE GLASSES
                        <br /> One pair of glasses to see far, intermediate and reading zones
                      </p>
                      <a className="link-arrow-left" href="/sg/en/news/progressive-cp">
                        More details
                      </a>
                    </div>
                  </div>
                </div>
                <li>
                  <h3>
                    <span>Option #2</span>Contact Lenses
                  </h3>
                  <p className="p-img-left p-img-left-004 article-details__content-004-005__text-description">
                    <img
                      alt="Contact Lenses"
                      className="article-details__img-content"
                      loading="lazy"
                      src={`${baseImgUrl}/sg-004/main-3.webp`}
                    />
                    Contact lenses are the alternative to eyeglasses for many wanting to correct
                    vision-related issues, like presbyopia, as they are convenient and hassle-free.
                    To correct presbyopia, most turn to bifocal or multifocal contact lenses to help
                    them see comfortably.
                  </p>
                  <p className="article-details__content-004-005__text-description">
                    While both lenses are similar, and people often use both terms interchangeably,
                    there is a slight difference. Bifocal contact lenses provide two focal points
                    (near and far). Meanwhile, multifocal contact lenses can include more than two
                    focal points, including an in-between zone of around three feet. Both options
                    come in soft or gas-permeable versions.
                  </p>
                  <div className="article-details__content-004-005__pb-48">
                    <div className="article-details__button-group-4">
                      <ButtonLink
                        className="article-details__button-group-4-warp"
                        href="/sg/en/contacts/clear-contactlens"
                      >
                        See OWNDAYS Contact Lenses
                      </ButtonLink>
                    </div>
                  </div>
                </li>
                <li>
                  <h3>
                    <span>Option #3</span>Surgery
                  </h3>
                  <p className="p-img-left p-img-left-004 article-details__content-004-005__text-description">
                    <img
                      alt="Surgery"
                      className="article-details__img-content"
                      loading="lazy"
                      src={`${baseImgUrl}/sg-004/main-4.webp`}
                    />
                    Those seeking a permanent solution to correct their vision can opt for
                    refractive surgery to change the shape of their cornea and improve close-up
                    vision in the nondominant eye. However, you may still need eyeglasses for
                    close-up work after surgery. It is advisable to consult an eye doctor about the
                    possible side effects before undergoing treatment.
                  </p>
                  <p className="article-details__content-004-005__text-description">
                    Regardless of whether you have exhibited symptoms of presbyopia, we recommend
                    having your eyes and vision checked regularly to ensure prompt detection of
                    age-related vision changes. Safeguarding your eye health, especially after age
                    40, is essential in minimising the effects of presbyopia.
                  </p>
                </li>
              </ol>
            </div>
            <div>
              <h2>Book Free Eye Test</h2>
              <p className="p-img-single">
                <img
                  alt="Book Free Eye Test at OWNDAYS"
                  loading="lazy"
                  src={`${baseImgUrl}/shops/shop.webp`}
                />
              </p>
              <p className="article-details__content-004-005__text-description">
              At OWNDAYS, we offer complimentary eye tests across our various stores islandwide to
                help you determine the appropriate prescriptions for your corrective eyewear. Enjoy
                a hassle-free experience when booking your free eye test online and skip the queue
                today!
              </p>
              <div className="article-details__button-group-2">
                <ButtonLink
                  className="article-details__button-group-2-warp"
                  href="/sg/en/services/lens"
                >
                  See All Optional Lenses
                </ButtonLink>
                <ButtonLink
                  className="article-details__button-group-2-warp"
                  href="/sg/en/bookeyetest"
                >
                  Book Free Eye Test
                </ButtonLink>
              </div>
            </div>
          </div>
          <div className="article-details__container-2">
            <div className="article-details__customer-guarantees">
              <div className="article-details__customer-guarantees-flex">
                <img
                  alt="Peace Of Mind icon"
                  loading="lazy"
                  src={`${baseImgUrl}/icons/peace-of-mind.svg`}
                />
                <div className="article-details__customer-guarantees-flex-text">
                  <h3>OWNDAYS Customer Guarantees</h3>
                  <p className="article-details__content-004-005__text-description">
                    Proper care and regular maintenance can extend the lifespan of your spectacles.
                    OWNDAYS offers you the most comprehensive after-sales service in the optical
                    industry so you can enjoy your OWNDAYS spectacles at all times.
                  </p>
                </div>
              </div>
              <ButtonLink
                className="article-details__button-group-3"
                href="/sg/en/services/warranty"
              >
                More Details
              </ButtonLink>
            </div>
          </div>
          <div className="article-details__container">
            <div className="button-group button-group--2">
              <a href="/sg/en/eyewear/eyeglasses.html" className="button button--primary">
                See All Eyeglasses
              </a>
              <a href="/stores/sg" className="button button--primary">
                Search For Nearest OWNDAYS Stores
              </a>
            </div>
          </div>
        </div>
        <ArticleDetailsFooter permalink={permalink} />
      </div>
    </>
  );
};

export default SG004;
