import React from 'react';
import { ARTICLES } from '../Articles/Articles';
import ArticleDetailsHeader from './ArticleDetailsHeader';
import ArticleDetailsFooter from './ArticleDetailsFooter';
import './ArticleDetails.scss';
import ArticleDetailsBreadcrumb from './ArticleDetailsBreadcrumb';

const SG001 = () => {
  const permalink = 'guide-to-adjusting-to-progressive-lenses'
  const article = ARTICLES?.find(article => article?.permalink === permalink);

  return (
    <>
      <ArticleDetailsBreadcrumb article={article} />
      <div className="article-details">
        <ArticleDetailsHeader article={article}/>
        <div className="article-details__content">
          <div className="article-details__container">
            <p>{article.description}</p>
            <div>
              <h2>What Are Progressive Lenses?</h2>
              <p className="p-img-single">
                <img
                  src="https://static1.lenskart.com/media/owndays/img/articles/sg-001/main-2.webp"
                  alt="What Are Progressive Lenses?"
                  loading="lazy"
                />
              </p>
              <p>
                Before we begin, let us provide some context. So, what are progressive lenses? Well,
                they are multifocal lenses that correct your vision. The term “multifocal” means the
                lenses have more than one area of focus, enabling you to see clearly at various
                distances. As a result, they are ideal for numerous tasks, from reading a book up
                close to focusing on the road ahead as you drive. All you need is this same pair of
                glasses.
              </p>
              <div className="button-group">
                <a href="/sg/en/news/progressive-cp" className="button button--primary">
                  What are Progressive Lenses
                </a>
              </div>
            </div>
            <div>
              <h2>How To Adjust To Your Progressive Lenses</h2>
              <ol>
                <li>
                  <h3>
                    <span>Tip #1</span>Consult Eyecare Professionals
                  </h3>
                  <p>
                    At OWNDAYS, we offer four types of progressive lenses, namely Enhanced,
                    Prestige, Prestige Gold and Prestige Platinum lenses, to cater to different
                    needs. As every individual is unique and leads a different lifestyle, there is
                    no one-size-fits-all approach to the fitting. It is therefore advisable to
                    consult our licensed opticians and optometrists who can conduct an eye
                    assessment to ensure your lenses are correctly measured and centred. For
                    instance, the centre of your pupil must align with the lens centre for it to
                    work. That can help facilitate a quicker adaptation to these lenses.
                  </p>
                  <p className="p-img-single">
                    <img
                      src="https://static1.lenskart.com/media/owndays/img/articles/sg-001/main-3.webp"
                      alt="Consult Eyecare Professionals"
                      loading="lazy"
                    />
                  </p>
                  <p>
                    An eyecare practitioner can also recommend the appropriate spectacle frame for
                    your progressive lenses, ensuring your glasses are correctly positioned whenever
                    you wear them. Crucially, the nose pads should rest comfortably on your nose,
                    with the temples securely hooked behind your eyes. If the top of your frame is
                    within your line of sight, your glasses are too low.
                  </p>
                  <div className="button-group">
                    <a href="/sg/en/eyewear/eyeglasses.html" className="button button--primary">
                      See OWNDAYS Spectacle Frames
                    </a>
                  </div>
                </li>
                <li>
                  <h3>
                    <span>Tip #2</span>Take It Slow
                  </h3>
                  <p>
                    It is perfectly normal for you to feel slightly different when first wearing
                    your progressive lenses. When this happens, consider starting slow by wearing
                    your glasses for brief periods before gradually increasing the amount of time
                    you wear them. This way, you can reduce discomfort while helping your eyes
                    adjust to your new lenses.
                  </p>
                </li>
                <li>
                  <h3>
                    <span>Tip #3</span>Wear Your Progressive Glasses Consistently
                  </h3>
                  <p className="p-img-left">
                    <img
                      src="https://static1.lenskart.com/media/owndays/img/articles/sg-001/main-4.webp"
                      alt="Wear Your Progressive Glasses Consistently"
                      loading="lazy"
                    />
                    While it is fine to take things slow as you adjust to your progressive glasses,
                    you should still wear them consistently throughout the day to speed up the
                    adjustment process. Think of this period as a boot camp for your eyes. Sticking
                    with it will help keep your eyes in shape and ensure a quicker adaptation to
                    your progressive lenses.
                  </p>
                  <p>
                    That also means sticking with your current spectacles and avoiding switching to
                    contacts or even your old glasses (it is time to bid them adieu). In fact,
                    constantly changing between various lenses can make it more challenging for your
                    eyes to adapt. So, avoid this headache by committing to your progressives.
                  </p>
                </li>
                <li>
                  <h3>
                    <span>Tip #4</span>Keep These Focusing Tricks In Mind
                  </h3>
                  <p className="p-img-left">
                    <img
                      src="https://static1.lenskart.com/media/owndays/img/articles/sg-001/main-5.webp"
                      alt="Keep These Focusing Tricks In Mind"
                      loading="lazy"
                    />
                    Are you experiencing a distortion in your peripheral vision? Rest assured that
                    there is nothing wrong with the progressive lenses. The lens design of
                    progressive glasses features a unique shape, allowing for a gradual transition
                    from distance to close-up vision correction. However, this shape can cause the
                    bottom right and left of both lenses to become distorted, resulting in blurry
                    vision if you look through that part of the lens.
                  </p>
                  <p>
                    Try this exercise at home: while seated, shift your eyes between watching TV and
                    looking at your phone. This helps your eyes get used to the motion of shifting
                    up and down between the different viewing zones. Conversely, if you are looking
                    at something within arm's length, say a computer screen, lower your eyes to the
                    middle part of the lens. Meanwhile, to look at something up close, like a book
                    or your mobile device, focus on the bottom part of the lens for clear vision.
                    Keep these tips in mind to help you get the hang of seeing with your new
                    glasses.
                  </p>
                </li>
                <li>
                  <h3>
                    <span>Tip #5</span>Maintain A Positive Attitude
                  </h3>
                  <p className="p-img-left">
                    <img
                      src="https://static1.lenskart.com/media/owndays/img/articles/sg-001/main-6.webp"
                      alt="Maintain A Positive Attitude"
                      loading="lazy"
                    />
                    Don’t worry if it takes a while for you to get to grips with your progressive
                    lenses. Approach it with a positive attitude and an open mind. With some
                    practice and patience, we are confident you will soon adapt to your new pair of
                    glasses. If you still have trouble adapting to your progressive lenses after
                    trying these tips, remember that everyone’s adjustment period is different.
                  </p>
                  <p>
                    Besides, it takes a couple of weeks to adjust fully to progressive lenses for
                    most people. However, if you remain uncertain, feel free to visit any OWNDAYS
                    store to consult our staff.
                  </p>
                </li>
              </ol>
            </div>
            <div className="article-details__intro">
              <h3>Introduction</h3>
              <div className="article-details__intro-grid">
                <img
                  src="https://static1.lenskart.com/media/owndays/img/articles/sg-001/progressive-cp.webp"
                  alt="OWNDAYS PROGRESSIVE GLASSES"
                  loading="lazy"
                />
                <div>
                  <p>
                    OWNDAYS PROGRESSIVE GLASSES <br/>One pair of glasses to see far, intermediate and
                    reading zones
                  </p>
                  <a className="link-arrow-left" href="/sg/en/news/progressive-cp">
                    More details
                  </a>
                </div>
              </div>
            </div>
            <div>
              <h2>Come speak to us!</h2>
              <p className="p-img-single">
                <img
                  src="https://static1.lenskart.com/media/owndays/img/articles/sg-001/reception.webp"
                  alt="Book your complimentary eye test with us or visit our Lens page to learn more about the different types of progressive lenses available at OWNDAYS."
                  loading="lazy"
                />
              </p>
              <p>
                We will be glad to work with you to identify the best progressive lenses and
                personalisation that meet your needs. Book your complimentary eye test with us or
                visit our Lens page to learn more about the different types of progressive lenses
                available at OWNDAYS.
              </p>
              <div className="button-group button-group--2">
                <a href="/sg/en/services/lens" className="button button--primary">
                  See All Optional Lenses
                </a>
                <a href="/stores/sg" className="button button--primary">
                  Book Free Eye Test
                </a>
              </div>
            </div>
            <div className="article-details__related">
              <h3>OWNDAYS Customer Guarantees</h3>
              <div className="article-details__related-grid">
                <img
                  src="https://static1.lenskart.com/media/owndays/img/articles/sg-001/warranty03.svg"
                  alt="OWNDAYS Customer Guarantees"
                  loading="lazy"
                />
                <div>
                  <p>
                    Proper care and regular maintenance can extend the lifespan of your spectacles.
                    OWNDAYS offers you the most comprehensive after-sales service in the optical
                    industry so you can enjoy your OWNDAYS spectacles at all times.
                  </p>
                  <a className="link-arrow-right" href="/sg/en/services/warranty">
                    More Details
                  </a>
                </div>
              </div>
            </div>
            <div className="button-group button-group--2">
              <a href="/sg/en/eyewear/eyeglasses.html" className="button button--primary">
                See All Eyeglasses
              </a>
              <a href="/stores/sg" className="button button--primary">
                Search For Nearest OWNDAYS Stores
              </a>
            </div>
          </div>
        </div>
        <ArticleDetailsFooter permalink={permalink}/>
      </div>
    </>
  );
};

export default SG001;
