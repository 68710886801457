export const ARTICLES = [
  {
    permalink: 'why-it-is-important-to-get-your-eye-prescription-right',
    date: '2025.04.10',
    img: 'https://static.lenskart.com/owndays/img/articles/sg-005/main.webp',
    title: 'Why It Is Important To Get Your Eye Prescription Right',
    description: `Is your vision getting blurry even when you have your spectacles on?<br/>
In that case, you should consider getting your eye prescription checked. Not only does the wrong eye prescription affect your ability to see clearly, but having a pair of prescription glasses that no longer optimises your vision may accelerate the degradation of your eyesight.`,
  },
  {
    permalink: 'what-you-need-to-know-about-presbyopia',
    date: '2025.04.10',
    img: 'https://static.lenskart.com/owndays/img/articles/sg-004/main.webp',
    title: 'Understanding Presbyopia and Treatment Options',
    description: `Our eyes’ natural lenses grow less flexible when we age, impacting our ability to focus on near objects. It is a natural part of the ageing process and is referred to as presbyopia, more commonly known as "Lao Hua Yan" ("老花眼").<br/>
Presbyopia usually develops around age 40 and will gradually worsen until your mid-60s. Those with the condition may notice that reading or other close-up tasks become more challenging, and they might find themselves needing to hold a book or phone at arm’s length to decipher the text.<br/>
Fortunately, presbyopia is a common refractive error that eye care specialists can help correct with eyeglasses, contact lenses, or surgery. Let us share more details about the condition to help you understand its causes and the correction options available to you.`,
  },
  {
    permalink: '4-signs-that-indicate-you-should-get-your-eyes-checked',
    date: '2024.11.14',
    img: 'https://static1.lenskart.com/media/owndays/img/articles/sg-003/main.webp',
    title: 'Top 4 Signs That Indicate You Should Get Your Eyes Checked',
    description: `Many vision problems are best addressed early. However, the symptoms of these issues are often not prominent, especially in the early stages. As such, it can be challenging for individuals to recognise that something might be wrong with their eyes. That is why regular eye examinations are essential for maintaining eye health.`,
  },
  {
    permalink: 'manage-myopia-and-prevent-it-from-worsening',
    date: '2024.11.14',
    img: 'https://static1.lenskart.com/owndays/img/articles/sg-002/main-20250408.webp',
    title: 'How to Manage Myopia and Prevent It from Worsening',
    description: `As a parent, noticing your child's vision changing can be concerning. Myopia, or nearsightedness, is increasingly common among children today. While myopia has no known cure, you can successfully manage this vision-related condition with prescription glasses, myopia control lenses, or contact lenses. For children, early interventions, such as limiting screen time, can also help prevent myopia progression.`,
  },
  {
    permalink: 'guide-to-adjusting-to-progressive-lenses',
    date: '2024.11.14',
    img: 'https://static1.lenskart.com/media/owndays/img/articles/sg-001/main.webp',
    title: 'Your Guide To Adjusting To Progressive Lenses Effortlessly',
    description: `Are you still getting used to your new pair of progressive glasses? Fret not! As you adjust to your progressive lenses, it is normal to experience a difference compared to wearing your usual single vision lenses. We recommend wearing them at home for a few days to adjust before venturing out or driving. Beyond that, we also have several simple progressive lens adjustment tips to help you adapt to your new eyewear effortlessly.`
  }
];
