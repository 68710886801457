import React from 'react';
import { ARTICLES } from '../Articles/Articles';
import ArticleDetailsBreadcrumb from './ArticleDetailsBreadcrumb';
import ArticleDetailsHeader from './ArticleDetailsHeader';
import ArticleDetailsFooter from './ArticleDetailsFooter';
import './ArticleDetails.scss';

const symptomsList = ['Blurry vision', 'Eye strain', 'Eye fatigue', 'Headaches and migraines'];

const SG005 = () => {
  const permalink = 'why-it-is-important-to-get-your-eye-prescription-right';
  const article = ARTICLES?.find(article => article?.permalink === permalink);
  const baseImgUrl = 'https://static.lenskart.com/owndays/img/articles';

  return (
    <>
      <ArticleDetailsBreadcrumb article={article} />
      <div className="article-details">
        <ArticleDetailsHeader article={article} />
        <div className="article-details__content">
          <div className="article-details__container">
            <p
              dangerouslySetInnerHTML={{ __html: article.description }}
              className="article-details__content-004-005__text-description"
            />
            <div>
              <h2>Effects Of Wearing The Wrong Eyeglass Prescription</h2>
              <p className="p-img-single">
                <img
                  alt="Effects Of Wearing The Wrong Eyeglass Prescription"
                  loading="lazy"
                  src={`${baseImgUrl}/sg-005/main-2.webp`}
                />
              </p>
              <p className="article-details__content-004-005__text-description">
                Don’t underestimate the importance of having an accurate eye prescription. In
                addition to affecting your eyesight, the wrong prescription can cause several
                temporary discomforts and vision-related issues, including
              </p>
            </div>
          </div>
          <div className="article-details__content-004-005__container-list">
            <div className="article-details__content-004-005__symptoms-grid">
              {symptomsList.map((text, index) => (
                <p
                  key={index}
                  className={`article-details__content-004-005__list-2${
                    index === symptomsList.length - 1
                      ? ' article-details__content-004-005__mb-25'
                      : ''
                  }`}
                >
                  <span>{index + 1}</span>
                  {text}
                </p>
              ))}
            </div>
          </div>
          <div className="article-details__container">
            <p className="article-details__content-004-005__text-p">
              It can even affect younger children’s visual development, with the inaccurate
              prescription causing out-of-focus images and vision problems, like amblyopia (lazy
              eye). The risk depends on the child’s age and level of visual development, with
              children under the age of 9 particularly vulnerable.
            </p>
            <p className="article-details__content-004-005__text-description">
              So, if you or your child notice persistent discomfort or vision problems while wearing
              your spectacles, consult an eye care practitioner immediately. We also recommend
              scheduling regular eye tests to maintain good eye health and ensure your prescription
              glasses are accurate and up-to-date so you will be able see clearly and comfortably.
            </p>
            <div className="article-details__button-group-4">
              <a
                className="article-details__button-group-4-warp"
                href="https://www.owndays.com/stores/sg"
              >
                Book Free Eye Test at OWNDAYS
              </a>
            </div>
            <div className="article-details__content-004-005">
              <h2>Understanding When To Update Your Eye Prescription</h2>
              <p className="p-img-single">
                <img
                  alt="Effects Of Wearing The Wrong Eyeglass Prescription"
                  loading="lazy"
                  src={`${baseImgUrl}/sg-005/main-3.webp`}
                />
              </p>
              <p className="article-details__content-004-005__text-description">
                If you are not already aware, prescription lenses have an expiration date.
                Generally, prescription glasses last between one to two years. However, we recommend
                checking your prescription expiration date to be sure. Meanwhile, the timeframe is
                even shorter for contact lens prescriptions, which are out-of-date after one year.
              </p>
              <ol>
                <li className="article-details__content-004-005 article-details__content-004-005__img-2">
                  <p className="p-img-right p-img-right-005 article-details__content-004-005__text-p">
                    <span>
                      So, why do prescription glasses and contact lenses expire over time? There are
                      two reasons for this. First, your eyes change over time, necessitating a
                      prescription change. Second, it is possible for your eyes to undergo other
                      changes even if your prescription power remains unchanged. For instance, you
                      may need to visit an eye care practitioner to update your lens prescription if
                      you develop presbyopia.
                    </span>
                    <img
                      alt="Presbyopia"
                      className="article-details__content-004-005__img-2"
                      loading="lazy"
                      src={`${baseImgUrl}/sg-005/illust-1.svg`}
                    />
                  </p>
                  <p className="article-details__content-004-005__text-p">
                    As your eyes are constantly changing, your eye prescription is unlikely to
                    remain the same after a couple of years. So, if you cannot recall the last time
                    you updated your prescription lenses, consider scheduling an eye test
                    immediately to ensure that your prescription is still functioning properly so
                    you can continue enjoying clear vision.
                  </p>
                </li>
              </ol>
            </div>
            <div>
              <h2>Book Free Eye Test</h2>
              <p className="p-img-single">
                <img
                  alt="Book Free Eye Test at OWNDAYS"
                  loading="lazy"
                  src={`${baseImgUrl}/shops/shop.webp`}
                />
              </p>
              <p className="article-details__content-004-005__text-description">
                At OWNDAYS, we provide free eye tests at all stores to help customers keep their
                prescriptions up-to-date. We can also clarify any queries you may have about the
                changes to your visual comfort. Click here to book an appointment with your nearest
                OWNDAYS store today!
              </p>
              <div className="article-details__button-group-2">
                <a
                  className="article-details__button-group-2-warp"
                  href="/sg/en/eyewear/eyeglasses.html"
                >
                  See All Eyeglasses
                </a>
                <a className="article-details__button-group-2-warp" href="/sg/en/bookeyetest">
                  Book Free Eye Test
                </a>
              </div>
            </div>
          </div>
          <div className="article-details__container-2">
            <div className="article-details__customer-guarantees">
              <div className="article-details__customer-guarantees-flex">
                <img
                  alt="Peace Of Mind icon"
                  loading="lazy"
                  src={`${baseImgUrl}/icons/peace-of-mind.svg`}
                />
                <div className="article-details__customer-guarantees-flex-text">
                  <h3>OWNDAYS Customer Guarantees</h3>
                  <p className="article-details__content-004-005__text-description">
                    Proper care and regular maintenance can extend the lifespan of your spectacles.
                    OWNDAYS offers you the most comprehensive after-sales service in the optical
                    industry so you can enjoy your OWNDAYS spectacles at all times.
                  </p>
                </div>
              </div>
              <a className="article-details__button-group-3" href="/sg/en/services/warranty">
                More Details
              </a>
            </div>
          </div>
          <div className="article-details__container">
            <div className="button-group button-group--2">
              <a className="button button--primary" href="/sg/en/eyewear/eyeglasses.html">
                See All Eyeglasses
              </a>
              <a className="button button--primary" href="/stores/sg">
                Search For Nearest OWNDAYS Stores
              </a>
            </div>
          </div>
        </div>
        <ArticleDetailsFooter permalink={permalink} />
      </div>
    </>
  );
};

export default SG005;
